// Imports
import { revalidatePath } from 'next/cache'
import { isServer } from '@core-helpers'
import { API_CONFIG, getAPIConfig } from '@config/api.config'
import { getQueryString } from '@helpers/get-querystring.helpers'
import Rollbar from 'rollbar'

const rollbar = new Rollbar({
  accessToken:
    process.env.ROLLBAR_SERVER_TOKEN ||
    process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  environment: process.env.ROLLBAR_ENVIRONMENT,
})

const dispatchRollbarError = (errorMessage: string) => {
  rollbar.error(errorMessage, null, rollbarError => {
    if (rollbarError) {
      console.log('Rollbar error reporting failed:')
      console.log(rollbarError)
      return
    } else {
      console.log('Reported error to Rollbar: ', errorMessage)
    }
  })
}

// RequestError, can be accessed in a try/catch due to throwing an error
class RequestError extends Error {
  response

  constructor(message, request) {
    super(message)

    this.response = request

    Object.setPrototypeOf(this, new.target.prototype)
  }
}

// The actual native fetch wrapper that handles all operations
const _Fetch = async ({
  endpoint = '',
  params = null,
  body = null,
  method = 'GET', // GET, POST, PUT, PATCH, DELETE
  options = API_CONFIG.options,
  site = true,
  bridge = false,
}) => {
  const opts = getAPIConfig()

  let CONFIG = opts.config[site ? 'site' : 'base']

  if (site) {
    CONFIG = {
      ...opts.config[site ? 'site' : 'base'],
      url: typeof opts.global?.value === 'string' ? opts.global.value : '',
    }
  }
  if (bridge) {
    CONFIG = opts.config.bridge
  }

  // Merge the provided method with the base api request options (defined in api.constants.ts)
  const config = { ...CONFIG, method }

  // Start building the request url
  let url = `${CONFIG.url}`

  if (endpoint) {
    url = `${url}${endpoint}`
  }

  // If we have params provided
  // and we are dealing with a 'GET' request
  // turn them into a querystring (eg. ?bleddyn=cool&jorik=cooler)
  if (params) {
    if (!params.refreshCache) {
      delete params.refreshCache
    }

    const querystring = getQueryString(params)
    // Concat the request url with the querystring
    if (querystring) url = `${url}?${querystring}`
  }

  if (body) {
    // @ts-expect-error TODO: config typing
    config.body = JSON.stringify(body)
  }

  // Merge the provided options and default API options (defined in api.constants.ts)
  const nextOptions = { ...API_CONFIG.options, ...options }

  if (params?.refreshCache === '1') {
    revalidatePath(url)
  }

  const start = performance.now()
  // Create the actual request
  let request = null
  if (config.method === 'POST') {
    try {
      request = await fetch(url, config)
    } catch (error) {
      dispatchRollbarError(`SERVER ERROR: ${error?.message}: ${url}`)
    }
  } else {
    try {
      request = await fetch(url, nextOptions)
    } catch (error) {
      dispatchRollbarError(`SERVER ERROR: ${error?.message}: ${url}`)
      return {
        error: true,
        message: error?.message,
      }
    }
  }
  const end = performance.now()

  // Request failed
  // throw an error
  if (!request?.ok) {
    if (request?.status !== 404) {
      dispatchRollbarError(`${request.status} ERROR - ${url}`)
    }
    const errorResponse = await request.json()

    return {
      error: true,
      message: `${request.status} ERROR - ${url}`,
      data: {
        error: true,
        requestUrl: url,
        response: errorResponse,
      },
    }
  }

  if (isServer) {
    console.log(`[${request.status}] | ${(end - start).toFixed(0)}ms | ${url}`)
  }

  // Request has passed, parse the actual response
  const response = await request.json()

  // Return the code, data and headers for further use
  return {
    url: url,
    status: response.status || request.status,
    headers: request.headers,
    data: response,
  }
}

// Below is just a simplification to initialize
export const API_CLIENT = {
  get: (
    url,
    params,
    options = API_CONFIG.options,
    site = true,
    bridge = false
  ) => {
    return _Fetch({
      endpoint: url,
      params,
      method: 'GET',
      options,
      site,
      bridge,
    })
  },

  post: (url, body, params, options = API_CONFIG.options, site = true) => {
    return _Fetch({
      endpoint: url,
      body,
      params,
      method: 'POST',
      options,
      site,
    })
  },

  put: (url, params, options = {}) => {
    // @ts-expect-error TODO: type _Fetch, make options + site optional
    return _Fetch({ endpoint: url, params, method: 'PUT', options, site })
  },

  patch: (url, params, options = {}) => {
    // @ts-expect-error TODO: type _Fetch, make options + site optional
    return _Fetch({ endpoint: url, params, method: 'PATCH', options, site })
  },

  delete: (url, params, options = {}) => {
    // @ts-expect-error TODO: type _Fetch, make options + site optional
    return _Fetch({ endpoint: url, params, method: 'DELETE', options, site })
  },
}
